import React from "react";

function IC_LINKDIN() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#D0D5DD" />
      <path
        d="M31 15C31.5304 15 32.0391 15.2107 32.4142 15.5858C32.7893 15.9609 33 16.4696 33 17V31C33 31.5304 32.7893 32.0391 32.4142 32.4142C32.0391 32.7893 31.5304 33 31 33H17C16.4696 33 15.9609 32.7893 15.5858 32.4142C15.2107 32.0391 15 31.5304 15 31V17C15 16.4696 15.2107 15.9609 15.5858 15.5858C15.9609 15.2107 16.4696 15 17 15H31ZM30.5 30.5V25.2C30.5 24.3354 30.1565 23.5062 29.5452 22.8948C28.9338 22.2835 28.1046 21.94 27.24 21.94C26.39 21.94 25.4 22.46 24.92 23.24V22.13H22.13V30.5H24.92V25.57C24.92 24.8 25.54 24.17 26.31 24.17C26.6813 24.17 27.0374 24.3175 27.2999 24.5801C27.5625 24.8426 27.71 25.1987 27.71 25.57V30.5H30.5ZM18.88 20.56C19.3256 20.56 19.7529 20.383 20.0679 20.0679C20.383 19.7529 20.56 19.3256 20.56 18.88C20.56 17.95 19.81 17.19 18.88 17.19C18.4318 17.19 18.0019 17.3681 17.685 17.685C17.3681 18.0019 17.19 18.4318 17.19 18.88C17.19 19.81 17.95 20.56 18.88 20.56ZM20.27 30.5V22.13H17.5V30.5H20.27Z"
        fill="#667085"
      />
    </svg>
  );
}

export default IC_LINKDIN;
