import {
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router";
import LogoBlack from "../../../Assets/logos/LogoBlack";
import IC_YOUTUBE from "../../../Assets/icons/IC_YOUTUBE";
import IC_FACEBOOK from "../../../Assets/icons/IC_FACEBOOK";
import IC_LINKDIN from "../../../Assets/icons/IC_LINKDIN";
import IC_INSTAGRAM from "../../../Assets/icons/IC_INSTAGRAM";
import LinkButtonFooter from "../components/LinkButtonFooter";

export default function Footer() {
  const desktop = useMediaQuery("(min-width: 720px)");

  const nav = useNavigate();
  const theme = useTheme();
  return (
    <Stack
      id="footer"
      sx={{
        background: "#F9FAFB",
        overflow: "clip",
        [theme.breakpoints.down("desktop")]: {},
        [theme.breakpoints.down("tablet")]: {
          padding: "24px 18px ",
        },
      }}
    >
      <Stack
        spacing="72px"
        sx={{
          zIndex: 1,
          maxWidth: "1280px",
          padding: "48px 24px 48px 24px",
          width: "100%",
          alignSelf: "center",
          [theme.breakpoints.down("tablet")]: {
            padding: "48px 32px",
          },
          position: "relative",
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            color: "#E3FBCC",
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
            fontSize: 500,
            fontWeight: 700,
            left: -100,
            bottom: -150,
            zIndex: -1,
            pointerEvents: "none",
            transform: "translateY(100px)",
          }}
        >
          Cashifysell
        </Typography>
        {/* The logo, its menu, and subscription container  */}
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            //
            [theme.breakpoints.down("desktop")]: {
              flexDirection: "row",
              justifyContent: "space-between",
            },
            //
            [theme.breakpoints.down("tablet")]: {
              justifyContent: "center",
              gap: "32px",
            },
          }}
        >
          <Stack
            spacing={"92px"}
            sx={{
              justifyContent: "space-around",
            }}
          >
            <Stack
              sx={{
                gap: "16px",
              }}
            >
              <LogoBlack />
              <Typography
                width={"354px"}
                sx={{
                  fontSize: "15px",
                  fontWeight: "400",
                  color: "#475467",
                  [theme.breakpoints.down("desktop")]: {},
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "13px",
                    textAlign: "center",
                  },
                }}
              >
                Launch and Grow <br />
                Your Digital Products Store Effortlessly.
              </Typography>
            </Stack>

            <Stack
              sx={{
                gap: "16px",
                flexDirection: "row",
              }}
            >
              <IC_YOUTUBE />
              <IC_FACEBOOK />
              <IC_LINKDIN />
              <IC_INSTAGRAM />
            </Stack>
          </Stack>
          <Stack
            sx={{
              flexDirection: "row",
              gap: "64px",
            }}
          >
            <Stack
              alignItems={desktop ? "start" : "center"}
              justifyContent={desktop ? "flex-start" : "center"}
              spacing={"24px"}
            >
              <Typography
                color="#101828"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                Quick Links
              </Typography>
              <nav
                style={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "11px",
                }}
              >
                <LinkButtonFooter
                  observable="Home"
                  label="Home"
                  onClick={() => {
                    if (!window.document.getElementById("Home")) nav("/Home");
                    else {
                      window.scrollTo({
                        behavior: "smooth",
                        top: window.document.getElementById("Home")?.offsetTop,
                      });
                    }
                  }}
                />
                <LinkButtonFooter
                  label="Features"
                  observable="features"
                  onClick={() => {
                    if (!window.document.getElementById("features")) nav("/");
                    else {
                      window.scrollTo({
                        behavior: "smooth",
                        top: window.document.getElementById("features")
                          ?.offsetTop,
                      });
                    }
                  }}
                />
                <LinkButtonFooter
                  observable="pricing"
                  label="Pricing"
                  onClick={() => {
                    if (!window.document.getElementById("pricing"))
                      nav("/#pricing");
                    else {
                      window.scrollTo({
                        behavior: "smooth",
                        top: window.document.getElementById("pricing")
                          ?.offsetTop,
                      });
                    }
                  }}
                />
                <LinkButtonFooter
                  observable="Sell your products"
                  label="Sell your products"
                  onClick={() => {
                    if (!window.document.getElementById("Sell your products"))
                      nav("/#Sell your products");
                    else {
                      window.scrollTo({
                        behavior: "smooth",
                        top: window.document.getElementById(
                          "Sell your products"
                        )?.offsetTop,
                      });
                    }
                  }}
                />
                {/* <LinkButtonFooter label="Terms" href="/terms" />
              <LinkButtonFooter label="Privacy" href="/privacy" /> */}
              </nav>
            </Stack>
            <Stack
              alignItems={desktop ? "start" : "center"}
              justifyContent={desktop ? "flex-start" : "center"}
              spacing={"24px"}
            >
              <Typography
                color="#101828"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                Support
              </Typography>
              <nav
                style={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                <LinkButtonFooter
                  label="Documentation"
                  href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
                />
                <LinkButtonFooter
                  label="Tutorials"
                  href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
                />
                <LinkButtonFooter
                  label="Telegram Channel"
                  href="https://t.me/CashifybotOfficial"
                />
                <LinkButtonFooter
                  label="Help"
                  href="https://t.me/CashifySupportBot"
                />
              </nav>
            </Stack>
            <Stack
              alignItems={desktop ? "start" : "center"}
              justifyContent={desktop ? "flex-start" : "center"}
              spacing={"24px"}
            >
              <Typography
                color="#101828"
                sx={{ fontSize: "18px", fontWeight: "600" }}
              >
                Resources
              </Typography>
              <nav
                style={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  gap: "8px",
                }}
              >
                <LinkButtonFooter
                  label="Tutorials"
                  // href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
                />
                <LinkButtonFooter
                  label="How it Works"
                  // href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
                />
                <LinkButtonFooter
                  label="Affiliate Program"
                  // href="https://t.me/CashifybotOfficial"
                />
                <LinkButtonFooter
                  label="Developer API"
                  // href="https://t.me/CashifySupportBot"
                />
              </nav>
            </Stack>
          </Stack>
        </Stack>

        <Stack spacing={"30px"}>
          <Divider />
          <Stack
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
              flexDirection: "row",
            }}
          >
            <Typography
              textAlign={"center"}
              sx={{
                color: "black",
              }}
            >
              Copyright &copy; 2024. All rights reserved.{" "}
            </Typography>
            <Stack>
              <nav
                style={{
                  alignItems: "start",
                  justifyContent: "start",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "24px",
                }}
              >
                <LinkButtonFooter
                  label="Privacy Policy"
                  // href="https://documenter.getpostman.com/view/23844318/2sA3BobCFF"
                />
                <LinkButtonFooter
                  label="Terms & Conditions"
                  // href="https://youtube.com/channel/UCewTH2sb2knfPGlJC7KTWGA"
                />
                <LinkButtonFooter
                  label="Cookie Policy"
                  // href="https://t.me/CashifybotOfficial"
                />
                <LinkButtonFooter
                  label="Legal Notice"
                  // href="https://t.me/CashifySupportBot"
                />
              </nav>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {/* <Stack
        sx={{
          position: "absolute",
          left: "0",
          right: "0",
          bottom: "0",
          top: "7200px",
          zIndex: 0,
        }}
      >
        <img src={IMG_CASHIFYSELL} alt="" />
      </Stack> */}
    </Stack>
  );
}
