import { Divider, Stack, SvgIcon, Typography } from "@mui/material";
import React from "react";
import PricingCardImg from "../../../Assets/images/PricingCardsImg.png";
import DualColorHeading from "../components/DualColorHeading";
import ZButton from "../../../components/ZButton";
import IC_CREDIT_CARD from "../../../Assets/icons/IC_CREDIT_CARD";
import IC_CALCULATER from "../../../Assets/icons/IC_CALCULATER";
import IC_CHECK from "../../../Assets/icons/IC_CHECK";
function Pricing() {
  return (
    <Stack
      className="pricing-section"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        gap: "64px",
        padding: "100px 0 ",
      }}
    >
      <Stack>
        <div id="pricing" />
        <Stack spacing={"20px"}>
          <DualColorHeading
            text1="Simple, Transparent"
            text2="Pricing"
            color1="#101828"
            color2="#66C61C"
            fontSize={60}
            fontWeight={600}
            lineHeight={"72px"}
            props1={{ textAlign: "center" }}
          />
          <Typography
            width={"800px"}
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
              color: "#475467",
            }}
          >
            Cashify Store simplifies how you create, manage, and sell digital
            products. Whether you’re offering templates, software, or digital
            downloads, our platform has the tools you need to reach customers
            and maximize sales.
          </Typography>
        </Stack>
      </Stack>
      {/* Card/img and text */}
      <Stack
        sx={{
          flexDirection: "row",
          gap: "64px",
          paddingRight: "24px",
          border: "1px solid var(--Gray-200, #EAECF0)",
          background: " var(--Base-White, #FFF)",
          borderRadius: "24px",
          alignItems: "center",
        }}
      >
        {/* text */}
        <Stack
          sx={{
            width: "580px",
          }}
        >
          <Stack sx={{ padding: "24px" }}>
            <Typography
              sx={{
                fontSize: "72px",
                fontWeight: 600,
                color: "#101828",
                display: "inline",
                gap: "2px",
                lineHeight: "90px",
                letterSpacing: "-1.44px",
              }}
            >
              5
              <Typography
                sx={{
                  fontSize: "48px",
                  fontWeight: 600,
                  color: "#66C61C",
                  display: "inline",
                  textAlign: "center",
                  lineHeight: "60px",
                  letterSpacing: "-0.96px",
                  paddingBottom: "32px",
                }}
              >
                %
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "#475467",
                  display: "inline",
                }}
              >
                Per sale
              </Typography>
            </Typography>
            <Typography
              sx={{
                color: "#101828",
                fontSize: "30px",
                fontWeight: "600",
              }}
            >
              Commission Rates
            </Typography>
            <Typography
              sx={{ color: "#475467", fontSize: "18px", fontWeight: "400" }}
            >
              Pay only 5% per sale.
            </Typography>
            <Stack
              sx={{
                padding: "36px 24px 0px 24px",
              }}
            >
              <ZButton
                sx={{
                  padding: " 12px 18px",
                }}
                label="Start Selling Now"
              />
            </Stack>
          </Stack>
          <Stack sx={{ paddding: "32px 24px 40px 24px" }}>
            {/* icons with text */}
            <Stack
              sx={{
                gap: "32px",
                padding: "32px 24px 40px 24px ",
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "12px",
                }}
              >
                <SvgIcon htmlColor="#344054">
                  <IC_CREDIT_CARD />
                </SvgIcon>

                <Typography>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "28px",
                    }}
                  >
                    Fixed Transaction Fees
                  </Typography>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                    }}
                  >
                    Low fixed fee per transaction: 70 cents.
                  </Typography>
                </Typography>
              </Stack>
              <Divider />
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "12px",
                }}
              >
                <SvgIcon>
                  <IC_CALCULATER />
                </SvgIcon>

                <Typography>
                  <Typography
                    sx={{
                      color: "#101828",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "28px",
                    }}
                  >
                    What You Pay
                  </Typography>
                  <Typography
                    sx={{
                      color: "#475467",
                      fontSize: "16px",
                      fontWeight: "400",
                      lineHeight: "28px",
                    }}
                  >
                    Total: 5% + fixed fee per transaction.
                  </Typography>
                </Typography>
              </Stack>{" "}
              <Divider />
            </Stack>
            {/* Features text */}
            <Stack
              sx={{
                gap: "28px",
                padding: "32px 24px 40px 24px",
              }}
            >
              <Typography>FEATURES</Typography>
              <Stack
                sx={{
                  gap: "16px",
                }}
              >
                <Stack direction={"row"} sx={{ gap: "12px" }}>
                  <IC_CHECK />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#475467",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    No Hidden Fees:{" "}
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#475467",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      Only pay for what you sell.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ gap: "12px" }}>
                  <IC_CHECK />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#475467",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    Secure Payments:
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#475467",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      All transactions are encrypted.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ gap: "12px" }}>
                  <IC_CHECK />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#475467",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    Instant Payouts:
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#475467",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      Get paid fast for every sale.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ gap: "12px" }}>
                  <IC_CHECK />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#475467",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    24/7 Support:
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#475467",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      We're here to help anytime.
                    </Typography>
                  </Typography>
                </Stack>
                <Stack direction={"row"} sx={{ gap: "12px" }}>
                  <IC_CHECK />
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#475467",
                      fontWeight: "700",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    Custom Storefront:
                    <Typography
                      sx={{
                        display: "inline",
                        color: "#475467",
                        fontWeight: "400",
                        fontSize: "16px",
                        lineHeight: "28px",
                      }}
                    >
                      {" "}
                      Tailor your store to your brand.
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* img */}
        <Stack
          sx={{
            width: "428px",
            height: "542px",
          }}
        >
          <img
            src={PricingCardImg}
            alt=""
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Pricing;
