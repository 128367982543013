import { motion } from "framer-motion";

export default function FloatingIcon(props: {
  children: any;
  initial?: any;
  animate?: any;
  style?: any;
}) {
  return (
    <motion.div
      style={{
        position: "absolute",
        zIndex: 10,
        background: "#0000",
        height: "64px",
        width: "64px",
        padding: "12px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
      initial={{
        scale: 0.6,
        opacity: 0.4,
        boxShadow:
          "0px 0px 12.5px 0px rgba(255, 255, 255, 0.2) inset, 0px 24px 48px -12px rgba(16, 24, 40, 0)",
        ...props.initial,
      }}
      animate={{
        scale: 1,
        opacity: 1,
        background: "#48F",
        border: "1.12px solid #FFF",
        borderRadius: "16px",

        boxShadow:
          "0px 0px 12.5px 0px rgba(255, 255, 255, 0.2) inset, 0px 24px 48px -12px rgba(16, 24, 40, 0.7)",
        ...props.animate,
      }}
      transition={{
        duration: 1,
      }}
    >
      {props.children}
    </motion.div>
  );
}
