import React from "react";

function IC_SHOPPING_CART() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.33398 2.66927C1.33398 1.9329 1.93094 1.33594 2.66732 1.33594H3.95532C5.79942 1.33594 7.44661 2.55776 7.9096 4.35688L8.50466 6.66927H29.3336C30.0699 6.66927 30.6669 7.26623 30.6669 8.0026C30.6669 8.73898 30.0699 9.33594 29.3336 9.33594H29.1936L28.4767 15.7883C28.2766 17.5889 27.9505 19.151 26.8653 20.2066C25.7803 21.2623 24.2097 21.5453 22.4044 21.6958L11.5384 22.6013C11.4338 22.7978 11.3121 22.9869 11.1737 23.1662L10.7281 23.7433C11.1942 24.1779 11.5563 24.7226 11.773 25.3359H18.8949C19.444 23.7823 20.9257 22.6693 22.6673 22.6693C24.8765 22.6693 26.6673 24.4601 26.6673 26.6693C26.6673 28.8785 24.8765 30.6693 22.6673 30.6693C20.9257 30.6693 19.444 29.5562 18.8949 28.0026H11.773C11.2239 29.5562 9.74228 30.6693 8.00065 30.6693C5.79152 30.6693 4.00065 28.8785 4.00065 26.6693C4.00065 24.4601 5.79152 22.6693 8.00065 22.6693C8.0625 22.6693 8.12402 22.6707 8.18518 22.6734L9.06289 21.5366C9.2808 21.2543 9.36945 20.9079 9.3211 20.5719C9.31361 20.5242 9.30448 20.4791 9.29361 20.4369L5.75065 6.66927L5.32706 5.02147C5.17906 4.44636 4.63037 4.0026 3.95532 4.0026H2.66732C1.93094 4.0026 1.33398 3.40564 1.33398 2.66927ZM22.6673 28.0026C21.9309 28.0026 21.334 27.4057 21.334 26.6693C21.334 25.9329 21.9309 25.3359 22.6673 25.3359C23.4037 25.3359 24.0007 25.9329 24.0007 26.6693C24.0007 27.4057 23.4037 28.0026 22.6673 28.0026ZM8.00065 25.3359C8.73702 25.3359 9.33398 25.9329 9.33398 26.6693C9.33398 27.4057 8.73702 28.0026 8.00065 28.0026C7.26428 28.0026 6.66732 27.4057 6.66732 26.6693C6.66732 25.9329 7.26428 25.3359 8.00065 25.3359Z"
        fill="#66C61C"
      />
    </svg>
  );
}

export default IC_SHOPPING_CART;
