import { Box, Stack, SxProps } from "@mui/material";

export default function GlassBlocks(props: { sx: SxProps }) {
  return (
    <Stack sx={props.sx} spacing={"4.6px"}>
      <Stack
        direction={"row"}
        rowGap={"4.6px"}
        columnGap={"4.6px"}
        sx={{ opacity: 0.32 }}
      >
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
            background:
              "linear-gradient(152deg, #CACACA 21.14%, rgba(255, 255, 255, 0.00) 83.97%)",
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
      </Stack>
      <Stack
        direction={"row"}
        rowGap={"4.6px"}
        columnGap={"4.6px"}
        sx={{ opacity: 0.32 }}
      >
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
        <Box
          sx={{
            height: "93px",
            width: "93px",
            borderRadius: "20px",
            border: "1px solid #C1C1C1",
            opacity: 0.6,
          }}
        />
      </Stack>
    </Stack>
  );
}
