import { Chip, Stack, Typography } from "@mui/material";
import DualColorHeading from "./DualColorHeading";

export default function FeatureCard(props: {
  label?: string;
  dualHeading?: { text1: string; text2: string };
  img?: string;
  featuredContent?: any;
  description: string;
  direction: "row" | "row-reverse";
}) {
  return (
    <Stack
      gap={"40px"}
      padding={"44px 80px"}
      border={"1px solid #EAECF0"}
      direction={"row"}
      width={"1150px"}
      height={"550px"}
      borderRadius={"24px"}
      alignItems={"center"}
      flexDirection={props.direction}
    >
      {/* img */}
      <Stack
        sx={{
          width: "584px",
          height: "557px",
        }}
      >
        {props.featuredContent}
        {props.img && (
          <img
            src={props.img}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        )}
      </Stack>
      {/* test */}
      <Stack>
        <Stack
          sx={{
            width: "500px",
            alignItems: "start",
            justifyContent: "start",
            gap: "32px",
          }}
        >
          <Chip
            label={props.label}
            sx={{
              background: "#F3FEE7",
              padding: "6px 12px",
              color: "#000",
              fontSize: "16px",
              fontWeight: "400",
              borderRadius: "80px",
            }}
          />
          <Stack spacing={"16px"}>
            <DualColorHeading
              text1={props.dualHeading?.text1}
              text2={props.dualHeading?.text2}
              color1="#101828"
              color2="#66C61C"
              fontSize={48}
              fontWeight={600}
              lineHeight={"60px"}
              props1={{ textAlign: "start" }}
            />
            <Typography
              fontSize={18}
              fontWeight={400}
              lineHeight={"28px"}
              color="#475467"
            >
              {props.description}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}
