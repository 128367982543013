import { SxProps, Typography } from "@mui/material";

export default function DualColorHeading(props: {
  text1?: string;
  text2?: string;
  color1: string;
  color2: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: string;
  props1?: SxProps;
  props2?: SxProps;
}) {
  return (
    <Typography
      sx={{
        color: props.color1,
        fontSize: props.fontSize,
        fontWeight: props.fontWeight,
        lineHeight: props.lineHeight,
        display: "inline",
        ...props.props1,
      }}
    >
      {props.text1}{" "}
      <Typography
        sx={{
          color: props.color2,
          fontSize: props.fontSize,
          fontWeight: props.fontWeight,
          lineHeight: props.lineHeight,
          display: "inline",
          ...props.props2,
        }}
      >
        {props.text2}
      </Typography>
    </Typography>
  );
}
