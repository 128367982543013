import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import DualColorHeading from "../components/DualColorHeading";
import IC_MINUS from "../../../Assets/icons/IC_MINUS";
import IC_PLUS from "../../../Assets/icons/IC_PLUS";
export default function FAQ() {
  const [openAccordoin, setOpenAccordion] = useState<Boolean[]>([]);
  const theme = useTheme();
  return (
    <Stack
      className="support-section"
      sx={{
        alignItems: "center",
        padding: "100px 0",
      }}
    >
      <div id="faq" />
      <Stack
        sx={{
          maxWidth: "1280px",
          width: "100%",
          gap: "70px",
          flexDirection: "row",
          position: "relative",
          [theme.breakpoints.down("desktop")]: {},
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "12px",
          },
        }}
      >
        <Typography
          sx={{
            position: "absolute",
            color: "#F2F4F7",
            textAlign: "center",
            leadingTrim: "both",
            textEdge: "cap",
            fontSize: 378,
            fontWeight: 700,
            textTransform: "capitalize",
            left: -100,
            pointerEvents: "none",
          }}
        >
          FAQ
        </Typography>
        {/* img */}
        <Stack
          sx={{
            alignItems: "flex-start",
          }}
        >
          <Stack spacing={"16px"} sx={{}}>
            <DualColorHeading
              text1="Frequently"
              text2="Asked Questions"
              color1="#101828"
              color2="#66C61C"
              fontSize={48}
              fontWeight={600}
            />
            <Typography
              sx={{
                zIndex: 0,
              }}
            >
              Find answers to common questions about our platform, products, and
              services. If you have more queries, feel free to contact our
              support team.
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Accordion
            onChange={(e, checked) => {
              openAccordoin[0] = checked;
              setOpenAccordion([...openAccordoin]);
            }}
          >
            <AccordionSummary
              expandIcon={openAccordoin[0] ? <IC_MINUS /> : <IC_PLUS />}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "25px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                }}
              >
                How do I create a store on the platform?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "15px",
                  },
                }}
              >
                Creating your store is easy! Just sign up, set up your profile,
                and start adding your digital products. You’ll be able to manage
                your inventory, pricing, and more with our intuitive dashboard."
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, checked) => {
              openAccordoin[1] = checked;
              setOpenAccordion([...openAccordoin]);
            }}
          >
            <AccordionSummary
              expandIcon={openAccordoin[1] ? <IC_MINUS /> : <IC_PLUS />}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "25px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                }}
              >
                How can I migrate my subscribers from existing/other
                subscription bots?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "15px",
                  },
                }}
              >
                If you are already using another subscription management bot,
                follow the steps below to easily migrate your subscibers to
                cashifybot.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, checked) => {
              openAccordoin[2] = checked;
              setOpenAccordion([...openAccordoin]);
            }}
          >
            <AccordionSummary
              expandIcon={openAccordoin[2] ? <IC_MINUS /> : <IC_PLUS />}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "25px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                }}
              >
                What kind of products can I sell?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "15px",
                  },
                }}
              >
                Cashifbot allows you to sell digital assets, including files,
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, checked) => {
              openAccordoin[3] = checked;
              setOpenAccordion([...openAccordoin]);
            }}
          >
            <AccordionSummary
              expandIcon={openAccordoin[3] ? <IC_MINUS /> : <IC_PLUS />}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "25px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                }}
              >
                Can I use Cashifybot with multiple channels or groups?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "15px",
                  },
                }}
              >
                Of course! Cashifybot enables you to include multiple
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            onChange={(e, checked) => {
              openAccordoin[4] = checked;
              setOpenAccordion([...openAccordoin]);
            }}
          >
            <AccordionSummary
              expandIcon={openAccordoin[4] ? <IC_MINUS /> : <IC_PLUS />}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "25px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "20px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                }}
              >
                How do I get paid?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{
                  fontWeight: 300,
                  fontSize: "20px",
                  [theme.breakpoints.down("desktop")]: { fontSize: "15px" },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "15px",
                  },
                }}
              >
                Our customers are paid directly through Stripe Connect, as well
                as
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>
    </Stack>
  );
}
