import React from "react";

function IC_CUSTOMER_SUPPORT() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.9996 5.33464C11.6645 5.33464 8.4192 8.12778 8.03734 11.3621C8.20019 11.432 8.35714 11.5038 8.49936 11.5688C8.97423 11.781 9.86964 12.1811 10.2107 13.1905C10.3347 13.5576 10.3337 13.9501 10.3328 14.3153V20.354C10.3337 20.7192 10.3347 21.1116 10.2107 21.4788C9.86964 22.4882 8.97423 22.8882 8.49936 23.1004C8.01548 23.3218 7.36112 23.6205 6.88418 23.6592C6.35435 23.702 5.81796 23.5888 5.35256 23.3276C4.9299 23.0904 4.61258 22.7033 4.29118 22.311C4.17496 22.17 3.95496 21.9104 3.80044 21.7313C3.51796 21.404 3.1972 21.0324 2.93275 20.6862C2.49588 20.1145 2.05811 19.4294 1.84066 18.6334C1.6078 17.781 1.6078 16.8882 1.84066 16.0358C1.99844 15.4582 2.28395 14.9485 2.66083 14.4134C3.0258 13.8953 3.6474 13.1359 4.25542 12.3974C4.35344 12.2731 4.48822 12.1022 4.58787 11.9881C4.76832 11.7814 5.01803 11.5294 5.35256 11.3416L5.35882 11.3381C5.75071 6.334 10.547 2.66797 15.9996 2.66797C21.4523 2.66797 26.2485 6.33399 26.6405 11.3382L26.6467 11.3416C26.9812 11.5294 27.2309 11.7814 27.4115 11.9881C27.5111 12.1022 27.6459 12.2731 27.7439 12.3974C28.3519 13.1359 28.9735 13.8953 29.3386 14.4134C29.7153 14.9485 30.0008 15.4582 30.1587 16.0358C30.3915 16.8882 30.3915 17.781 30.1587 18.6334C29.9412 19.4294 29.5035 20.1145 29.0665 20.6862C28.8021 21.0324 28.4815 21.404 28.1989 21.7313C28.0445 21.9104 27.8244 22.17 27.7082 22.311C27.3918 22.697 27.0792 23.0784 26.6663 23.3165V23.7346C26.6663 27.0897 23.3885 29.3346 19.9996 29.3346H17.3329C16.5965 29.3346 15.9996 28.7377 15.9996 28.0013C15.9996 27.2649 16.5965 26.668 17.3329 26.668H19.9996C22.5017 26.668 23.9996 25.0924 23.9996 23.7346V23.3232C23.8234 23.2484 23.6531 23.1705 23.5 23.1004C23.0251 22.8882 22.1296 22.4882 21.7887 21.4788C21.6645 21.1116 21.6656 20.7192 21.6664 20.354V14.3153C21.6656 13.9501 21.6645 13.5576 21.7887 13.1905C22.1296 12.1811 23.0251 11.781 23.5 11.5688C23.6421 11.5038 23.7991 11.432 23.962 11.3621C23.5802 8.12778 20.3348 5.33464 15.9996 5.33464Z"
        fill="#66C61C"
      />
    </svg>
  );
}

export default IC_CUSTOMER_SUPPORT;
