import React from "react";

function IC_CALCULATER() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.002 6.00781C14.002 5.45553 14.4497 5.00781 15.002 5.00781H17.002C17.5543 5.00781 18.002 5.45553 18.002 6.00781C18.002 6.5601 17.5543 7.00781 17.002 7.00781H15.002C14.4497 7.00781 14.002 6.56009 14.002 6.00781Z"
        fill="#344054"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0032 1.00505C13.6879 1.00505 15.4 0.953666 17.0741 1.17864C18.4104 1.35821 19.5165 1.73982 20.3922 2.61516C21.1952 3.41769 21.5838 4.41487 21.7811 5.60825C21.9737 6.77247 21.9984 8.222 22.0024 9.99825C22.0027 10.1325 22.003 10.2669 22.0033 10.4012C22.0037 10.683 22.004 10.8239 21.9161 10.9119C21.8282 11 21.687 11 21.4045 11C15.1374 11 8.87019 11 2.603 11C2.32104 11 2.18006 11 2.09228 10.9126L2.09127 10.9116C2.00391 10.8238 2.00391 10.6828 2.00391 10.4009V9.99825C2.00793 8.222 2.03266 6.77247 2.22518 5.60825C2.42253 4.41487 2.81115 3.4177 3.61409 2.61516C4.48987 1.73982 5.59591 1.35821 6.93221 1.17864C8.60635 0.953666 10.3184 1.00505 12.0032 1.00505ZM7.19871 3.1598C6.08418 3.30957 5.47055 3.58664 5.02831 4.02865C4.62113 4.43563 4.35515 4.98638 4.19841 5.93423C4.08953 6.59267 4.04092 7.391 4.01954 8.39456C4.01349 8.67874 4.01046 8.82083 4.0987 8.91092C4.18694 9.00101 4.33018 9.00101 4.61665 9.00101H19.3897C19.6762 9.00101 19.8194 9.00101 19.9076 8.91092C19.9959 8.82083 19.9928 8.67874 19.9868 8.39456C19.9654 7.391 19.9168 6.59267 19.8079 5.93423C19.6512 4.98638 19.3852 4.43563 18.978 4.02865C18.5358 3.58664 17.9222 3.30957 16.8076 3.1598C15.6643 3.00617 14.1527 3.00405 12.0032 3.00405C9.85359 3.00405 8.342 3.00617 7.19871 3.1598Z"
        fill="#344054"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.999 9.00781H2.00494L2.00268 10.0052C2.00195 10.3258 2.00195 10.6604 2.00195 11.0071V13.0805C2.00193 15.1381 2.00191 16.784 2.17562 18.0756C2.35528 19.4114 2.73709 20.517 3.61287 21.3925C4.48864 22.2679 5.59468 22.6496 6.93098 22.8292C8.34304 23.019 9.78205 23.0121 11.209 23.0053C11.4738 23.0041 11.7383 23.0028 12.002 23.0028C12.2657 23.0028 12.5301 23.0041 12.7949 23.0053C14.2218 23.0121 15.6609 23.019 17.073 22.8292C18.4093 22.6496 19.5153 22.2679 20.3911 21.3925C21.2669 20.517 21.6487 19.4114 21.8283 18.0756C22.002 16.7839 22.002 15.1382 22.002 13.0805V11.0071C22.002 10.6602 22.002 10.3261 22.0013 10.0052L21.999 9.00781ZM6.00195 14.0079C6.00195 13.4556 6.44967 13.0079 7.00195 13.0079H7.52827C8.08055 13.0079 8.52827 13.4556 8.52827 14.0079C8.52827 14.5601 8.08055 15.0079 7.52827 15.0079H7.00195C6.44967 15.0079 6.00195 14.5601 6.00195 14.0079ZM10.7388 14.0079C10.7388 13.4556 11.1866 13.0079 11.7388 13.0079H12.2652C12.8174 13.0079 13.2652 13.4556 13.2652 14.0079C13.2652 14.5601 12.8174 15.0079 12.2652 15.0079H11.7388C11.1866 15.0079 10.7388 14.5601 10.7388 14.0079ZM15.4757 14.0079C15.4757 13.4556 15.9234 13.0079 16.4757 13.0079H17.002C17.5543 13.0079 18.002 13.4556 18.002 14.0079C18.002 14.5601 17.5543 15.0079 17.002 15.0079H16.4757C15.9234 15.0079 15.4757 14.5601 15.4757 14.0079ZM7.00195 17.0079C6.44967 17.0079 6.00195 17.4556 6.00195 18.0079C6.00195 18.5601 6.44967 19.0079 7.00195 19.0079H7.52827C8.08055 19.0079 8.52827 18.5601 8.52827 18.0079C8.52827 17.4556 8.08055 17.0079 7.52827 17.0079H7.00195ZM11.7388 17.0079C11.1866 17.0079 10.7388 17.4556 10.7388 18.0079C10.7388 18.5601 11.1866 19.0079 11.7388 19.0079H12.2652C12.8174 19.0079 13.2652 18.5601 13.2652 18.0079C13.2652 17.4556 12.8174 17.0079 12.2652 17.0079H11.7388ZM16.4757 17.0079C15.9234 17.0079 15.4757 17.4556 15.4757 18.0079C15.4757 18.5601 15.9234 19.0079 16.4757 19.0079H17.002C17.5543 19.0079 18.002 18.5601 18.002 18.0079C18.002 17.4556 17.5543 17.0079 17.002 17.0079H16.4757Z"
        fill="#344054"
      />
    </svg>
  );
}

export default IC_CALCULATER;
