import React from "react";

function FloatingIcStore() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 42">
      <path
        fill="#F7F7F7"
        fillRule="evenodd"
        d="M5.255 16.945c.957 0 1.733.776 1.733 1.733v7.799c0 2.5.003 4.2.174 5.474.165 1.223.455 1.79.84 2.176.386.386.953.676 2.177.84 1.273.172 2.974.175 5.474.175H26.05c2.5 0 4.2-.004 5.474-.175 1.223-.164 1.79-.454 2.176-.84.386-.386.676-.953.84-2.176.171-1.274.175-2.974.175-5.474v-7.799a1.733 1.733 0 113.466 0V26.6c0 2.347 0 4.282-.206 5.814-.216 1.608-.687 3.028-1.824 4.165s-2.558 1.608-4.165 1.824c-1.532.206-3.467.206-5.814.206H15.53c-2.346 0-4.281 0-5.813-.206-1.608-.216-3.028-.687-4.165-1.824s-1.609-2.557-1.825-4.165c-.206-1.532-.206-3.467-.206-5.814v-7.92c0-.958.776-1.734 1.734-1.734z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F7F7F7"
        fillRule="evenodd"
        d="M14.357 28.36a1.733 1.733 0 012.446-.146c.808.717 2.257 1.296 4.049 1.296 1.792 0 3.241-.58 4.049-1.296a1.733 1.733 0 112.3 2.593c-1.563 1.388-3.889 2.17-6.349 2.17-2.46 0-4.786-.782-6.35-2.17a1.733 1.733 0 01-.145-2.447z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#F7F7F7"
        d="M5.585 7.777c0-2.602 2.123-4.695 4.72-4.695h21.091c2.598 0 4.72 2.093 4.72 4.695 0 .954.25 1.892.728 2.721l1.71 2.97c.448.778.812 1.41.9 2.467.036.43.04.791-.015 1.14a3.927 3.927 0 01-.226.8l-.01.028a6.537 6.537 0 01-6.132 4.242 6.544 6.544 0 01-5.57-3.094c-1.566 1.894-3.973 3.094-6.65 3.094-2.677 0-5.085-1.2-6.65-3.094a6.544 6.544 0 01-5.57 3.094 6.537 6.537 0 01-6.132-4.242l-.01-.029a3.941 3.941 0 01-.227-.799c-.054-.349-.05-.71-.015-1.14.088-1.057.453-1.689.901-2.467l1.71-2.97c.477-.829.727-1.767.727-2.72z"
      ></path>
    </svg>
  );
}

export default FloatingIcStore;
