import { Stack } from "@mui/material";
import Header from "./sections/header";
import Hero from "./sections/hero";
import Features from "./sections/Features";
import CtaSection from "./sections/CtaSection";
import Pricing from "./sections/Pricing";
import GetStart from "./sections/GetStart";
import FAQ from "./sections/faq";
import Footer from "./sections/Footer";

export default function PageLanding() {
  return (
    <Stack
      sx={{
        height: "100vh",
        pt: "120px",
      }}
    >
      <Header />
      <Hero />
      <Features />
      <CtaSection />
      <Pricing />
      <GetStart />
      <FAQ />
      <Footer />
    </Stack>
  );
}
