import { useEffect, useState } from "react";
import { Button } from "@mui/material";

export default function LinkButton(props: {
  label?: string;
  href?: string;
  onClick?: any;
  active?: boolean;
  observable?: string;
}) {
  const [active, setActive] = useState(props.active);

  useEffect(() => {
    if (props.observable) {
      const el = document.querySelector(props.observable);
      if (!el) return;
      const observer = new window.IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            // Set active state.
            setActive(true);
            return;
          } else {
            // Set inactive state.
            setActive(false);
          }
        },
        {
          root: null,
          threshold: 0.1, // set offset 0.1 means trigger if atleast 10% of element in viewport
        }
      );

      observer.observe(el!);
    }
  }, []);

  return (
    <Button
      onClick={props.onClick}
      variant="text"
      disableElevation
      disableRipple
      disableTouchRipple
      href={props.href}
      sx={{
        fontWeight: "600",
        fontSize: "16px",
        color: "#D0D5DD",
        ":hover": {
          color: "#66C61C",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.label}
      <div
        style={{
          height: "2px",
          transition: "all .3s",
          background: active ? "#66C61C" : "#0000",
          width: "100%",
        }}
      />
    </Button>
  );
}
