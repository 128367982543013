import _img_customer_1 from "./customer1.jpg";
import _img_customer_2 from "./customer2.jpg";
import _img_customer_3 from "./customer3.jpg";
import _img_customer_4 from "./customer4.jpg";
import _img_customer_5 from "./customer5.jpg";

export const IMG_CUSTOMER_1 = _img_customer_1;
export const IMG_CUSTOMER_2 = _img_customer_2;
export const IMG_CUSTOMER_3 = _img_customer_3;
export const IMG_CUSTOMER_4 = _img_customer_4;
export const IMG_CUSTOMER_5 = _img_customer_5;
