import React from "react";

function IcUp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 15 16"
    >
      <g clipPath="url(#clip0_21989_5557)">
        <path
          stroke="#66C61C"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.889"
          d="M7.847 10.426V5.683m0 0l2.076 2.075M7.847 5.683L5.772 7.758m2.075 6.226a5.93 5.93 0 100-11.859 5.93 5.93 0 000 11.859z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_21989_5557">
          <path
            fill="#fff"
            d="M0 0H14.231V14.231H0z"
            transform="translate(.732 .938)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default IcUp;
