import React from "react";

function IC_FACEBOOK() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="47" height="47" rx="23.5" stroke="#D0D5DD" />
      <path
        d="M34 28.19C34 31.83 31.83 34 28.19 34H27C26.45 34 26 33.55 26 33V27.23C26 26.96 26.22 26.73 26.49 26.73L28.25 26.7C28.39 26.69 28.51 26.59 28.54 26.45L28.89 24.54C28.92 24.36 28.78 24.19 28.59 24.19L26.46 24.22C26.18 24.22 25.96 24 25.95 23.73L25.91 21.28C25.91 21.12 26.04 20.98 26.21 20.98L28.61 20.94C28.78 20.94 28.91 20.81 28.91 20.64L28.87 18.24C28.87 18.07 28.74 17.94 28.57 17.94L25.87 17.98C24.21 18.01 22.89 19.37 22.92 21.03L22.97 23.78C22.98 24.06 22.76 24.28 22.48 24.29L21.28 24.31C21.11 24.31 20.98 24.44 20.98 24.61L21.01 26.51C21.01 26.68 21.14 26.81 21.31 26.81L22.51 26.79C22.79 26.79 23.01 27.01 23.02 27.28L23.11 32.98C23.12 33.54 22.67 34 22.11 34H19.81C16.17 34 14 31.83 14 28.18V19.81C14 16.17 16.17 14 19.81 14H28.19C31.83 14 34 16.17 34 19.81V28.19Z"
        fill="#667085"
      />
    </svg>
  );
}

export default IC_FACEBOOK;
