import React from "react";

function IC_SHEILD() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5551 3.26302C12.048 2.44764 13.8112 1.66797 15.9956 1.66797C18.1798 1.66797 19.9438 2.44764 21.4375 3.26297C23.0219 4.12788 24.1132 4.89504 25.3947 5.32589C26.2547 5.61498 27.33 5.85493 27.9446 6.57478C28.4364 7.151 28.5474 7.91314 28.6704 8.63348C30.1122 17.0646 26.8127 26.3762 18.4243 29.7637C17.627 30.0857 17.0108 30.3346 15.9996 30.3346C14.9884 30.3346 14.3723 30.0858 13.575 29.7637C5.18677 26.3762 1.88497 17.0568 3.32474 8.63361C3.44782 7.91334 3.55869 7.15129 4.05047 6.57509C4.66493 5.85517 5.74034 5.61508 6.60023 5.32585C7.88119 4.89497 8.97126 4.12805 10.5551 3.26302ZM7.4333 7.79566C7.15257 7.89009 6.88891 7.98305 6.66041 8.06658C6.37063 8.17252 6.22574 8.22548 6.11081 8.36287C5.97491 8.52533 5.95783 8.71709 5.88902 9.11942C5.09474 13.7634 5.74231 18.1026 7.72133 21.4978C7.94101 21.8748 8.05085 22.0632 8.24679 22.0886C8.44274 22.1141 8.60361 21.9537 8.92535 21.633L22.5207 8.08406C22.8838 7.72237 23.0652 7.54151 23.0371 7.34385C23.0091 7.14618 22.7782 7.01909 22.3163 6.76493C21.5558 6.3464 20.8346 5.90373 20.1862 5.54981C18.8308 4.80993 17.5292 4.27409 15.9958 4.27409C14.4624 4.27409 13.1617 4.80984 11.8073 5.54958C10.5437 6.23968 9.00315 7.26761 7.4333 7.79566Z"
        fill="#66C61C"
      />
    </svg>
  );
}

export default IC_SHEILD;
