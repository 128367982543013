import React, { useEffect, useState } from "react";
import {
  Button,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

export default function LinkButtonFooter(props: {
  label?: string;
  href?: string;
  onClick?: any;
  active?: boolean;
  observable?: string;
}) {
  return (
    <Typography
      component={"a"}
      href={props.href}
      onClick={props.onClick}
      sx={{
        fontWeight: "600",
        fontSize: "14px",
        p: 0,
        cursor: "pointer",
        textDecoration: "none",
        color: "#475467",
        ":hover": {
          color: "#66C61C",
        },
      }}
    >
      {props.label}
    </Typography>
  );
}
