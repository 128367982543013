import React from "react";
import { RecoilRoot } from "recoil";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ThemeManager from "./Middleware/Thememanger";
import PageLanding from "./pages/landing";

function App() {
  return (
    <RecoilRoot>
      <ThemeManager>
        <Router>
          <Routes>
            <Route element={<PageLanding />} path="/"></Route>
          </Routes>
        </Router>
      </ThemeManager>
    </RecoilRoot>
  );
}

export default App;
