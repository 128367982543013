import React from "react";

function FloatingIcFile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 49">
      <g
        fill="#F7F7F7"
        fillRule="evenodd"
        clipPath="url(#clip0_21989_5324)"
        clipRule="evenodd"
      >
        <path d="M31.859 8.35c-1.518-.159-3.424-.159-5.805-.159H24.7c-2.926 0-5.266 0-7.102.234-1.892.24-3.477.751-4.742 1.946-1.279 1.208-1.837 2.741-2.098 4.571-.25 1.752-.25 3.978-.25 6.727v9.505c0 1.234 0 2.235.054 3.052.056.842.173 1.593.456 2.31.817 2.07 2.53 3.669 4.658 4.418 1.327.468 3.602.467 6.006.466 4.388 0 6.995.001 9.132-.752 3.43-1.208 6.172-3.778 7.476-7.08.434-1.1.627-2.282.72-3.699.091-1.392.091-3.112.091-5.304v-3.992c0-2.239 0-4.051-.17-5.5-.176-1.504-.552-2.799-1.433-3.91a7.736 7.736 0 00-1.598-1.51c-1.15-.815-2.482-1.16-4.042-1.323zm-16.94 4.221c.608-.574 1.467-.947 3.058-1.15 1.625-.206 3.774-.209 6.829-.209h1.163c2.486 0 4.234.002 5.578.143 1.32.138 2.068.396 2.62.787.374.264.702.574.977.921.395.498.657 1.167.8 2.383.146 1.252.148 2.886.148 5.24v4.483c0 .413 0 1.4-.433 2.13-.267.45-.61.825-.961 1.016a3.976 3.976 0 01-1.907.484l-1.607-.057c-.588-.012-1.274.007-1.935.184a3.735 3.735 0 00-2.641 2.641c-.178.662-.196 1.347-.184 1.935l.056 1.608c0 .723-.192 1.352-.528 1.937-.195.34-.537.653-1.008.927-.714.415-1.57.419-2.092.421-.523.003-1.074.005-1.535.005-2.834 0-3.865-.022-4.643-.296-1.349-.475-2.378-1.467-2.857-2.68-.12-.305-.205-.704-.25-1.396-.047-.706-.047-1.607-.047-2.906v-9.337c0-2.893.003-4.902.219-6.416.207-1.46.585-2.236 1.18-2.798z"></path>
        <path d="M14.803 20.394a1.545 1.545 0 01.882-2l10.086-3.914a1.545 1.545 0 111.118 2.882l-10.086 3.914a1.546 1.546 0 01-2-.882zM17.044 26.16a1.546 1.546 0 01.881-2l4.323-1.678a1.546 1.546 0 011.118 2.882l-4.322 1.677a1.546 1.546 0 01-2-.881z"></path>
      </g>
      <defs>
        <clipPath id="clip0_21989_5324">
          <path
            fill="#fff"
            d="M0 0H37.093V37.093H0z"
            transform="rotate(-21.21 38.395 4.96)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FloatingIcFile;
