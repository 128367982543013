import { Badge, Stack, Typography } from "@mui/material";
import React from "react";
import TestimonialImg from "../../../Assets/images/TestimonialImg.png";
import IC_STAR from "../../../Assets/icons/IC_STAR";

function TestimonialCard() {
  return (
    <Stack sx={{ padding: " 96px 0px" }}>
      <Stack
        direction={"row"}
        sx={{
          alignItems: "center",
          gap: "50px",
        }}
      >
        <Stack>
          <img src={TestimonialImg} alt="" />
        </Stack>
        <Stack gap={"44px"}>
          <Stack sx={{ gap: "24px", width: "800px" }}>
            <Stack
              sx={{
                flexDirection: "row",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Badge
                sx={{
                  color: "#3B7C0F",
                  background: "#F3FEE7",
                  padding: "4px 12px",
                  border: "1px solid var(--Green-light-200, #D0F8AB)",
                  borderRadius: "16px",
                }}
              >
                Sold 5k Copies of Design E-Book
              </Badge>
              <Typography
                sx={{
                  flexDirection: "row",
                }}
              >
                <IC_STAR />
                <IC_STAR />
                <IC_STAR />
                <IC_STAR />
                <IC_STAR />
              </Typography>
              <Typography>4.9</Typography>
            </Stack>
            <Typography
              sx={{
                fontSize: "36px",
                color: "#101828",
                fontWeight: "500",
                lineHeight: "44px" /* 122.222% */,
                letterSpacing: " -0.72px",
              }}
            >
              Cashifysell has completely transformed the way I manage my digital
              store. The simplicity of the platform and its intuitive design
              make my day-to-day operations so much smoother. I couldn't imagine
              going back to my old methods!
            </Typography>
          </Stack>
          <Stack>
            <Typography
              sx={{
                fontSize: "30px",
                color: "#101828",
                fontWeight: "600",
              }}
            >
              Sarah D.
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                color: "#475467",
                fontWeight: "400",
              }}
            >
              E-commerce Store Owner
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TestimonialCard;
