import { Stack, Typography } from "@mui/material";
import dashboardimg from "../../../Assets/images/FeaturesImg.png";
import Paymentsimg from "../../../Assets/images/paymentImg.png";

import { motion } from "framer-motion";
import { useState } from "react";
import DualColorHeading from "../components/DualColorHeading";
import { IMG_DECORATIVE_PATTERN } from "../../../Assets/images";
import IcUp from "../../../Assets/icons/IC_UP";
import FeatureCard from "../components/FeatureCard";

function InteractiveChartFeature() {
  const Bars = [
    {
      label: "$68k",
      height: "60px",
      month: "JAN",
    },
    {
      label: "$132k",
      height: "103px",
      month: "FEB",
    },
    {
      label: "$144k",
      height: "114px",
      month: "MAR",
    },
    {
      label: "$92k",
      height: "67px",
      month: "APR",
    },
    {
      label: "$124k",
      height: "96px",
      month: "MAY",
    },
    {
      label: "$160k",
      height: "128px",
      month: "JUN",
    },
    {
      label: "$180k",
      height: "146px",
      month: "JUL",
    },
  ];

  const [activeBar, setActiveBar] = useState(1);

  return (
    <Stack
      spacing={"20px"}
      justifyContent={"flex-end"}
      sx={{ minHeight: "200px" }}
    >
      {/* Bars */}
      <Stack direction={"row"} spacing={"8px"} alignItems={"flex-end"}>
        {Bars.map((b, i) => (
          <motion.div
            onHoverStart={(e, ii) => setActiveBar(i)}
            style={{
              width: "54px",
              borderRadius: "8px",
              padding: "8px",
              display: "flex",
              transition: "all .3s",
              alignItems: "center",
              flexDirection: "column",
              background: activeBar === i ? "#66C61C" : "#E7EEEC",
              height: b.height,
            }}
            initial={{
              height: 0,
              opacity: 0,
            }}
            whileInView={{
              height: b.height,
              opacity: 1,
            }}
            viewport={{ once: true }}
          >
            <Typography
              textAlign={"center"}
              fontSize={12}
              fontWeight={400}
              color={activeBar === i ? "#FFFFFF" : "#326212"}
            >
              {b.label}
            </Typography>
          </motion.div>
        ))}
      </Stack>
      {/* Months  */}
      <Stack direction={"row"} spacing={"8px"}>
        {Bars.map((b, i) => (
          <Typography
            sx={{
              width: "54px",
              textAlign: "center",
              fontSize: "12px",
              fontWeight: 500,
              transition: "all .3s",
              lineHeight: "18px",
              color: "#326212",
              transform: activeBar === i ? "translateY(-2px)" : undefined,
            }}
          >
            {b.month}
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
}

function Features() {
  return (
    <Stack className="features-section" alignItems={"center"} paddingTop="72px">
      <div id="features" />
      {/* Heading with Supporting Text */}
      <Stack
        sx={{
          width: "800px",
        }}
        spacing={"20px"}
      >
        <DualColorHeading
          text1="Everything You Need to Sell Digital"
          text2="With Ease"
          color1="#101828"
          color2="#66C61C"
          fontSize={60}
          fontWeight={600}
          lineHeight={"72px"}
          props1={{ textAlign: "center" }}
        />
        <Typography
          width={"800px"}
          sx={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "400",
            color: "#475467",
          }}
        >
          Cashify Store simplifies how you create, manage, and sell digital
          products. Whether you’re offering templates, software, or digital
          downloads, our platform has the tools you need to reach customers and
          maximize sales.
        </Typography>
      </Stack>

      <Stack
        alignItems={"start"}
        sx={{
          padding: "84px 34px",
          gap: "72px",
        }}
      >
        <FeatureCard
          description="Easily set up your online store in just a few clicks, no coding
              required. With a user-friendly interface and guided steps, anyone
              can launch their digital product store within minutes. Choose your
              store name, upload your logo, and you’re ready to go live."
          img={dashboardimg}
          label="Setup"
          dualHeading={{ text1: "Instant", text2: "Store Setup" }}
          direction="row"
        />
        <FeatureCard
          description="Accept payments securely from customers worldwide with built-in payment gateways that support all major cards and digital wallets. Every transaction is encrypted, ensuring safe and reliable payments for your customers. Your store will handle everything from checkout to confirmation."
          img={Paymentsimg}
          label="Payments"
          dualHeading={{ text1: "Secure", text2: "Payments" }}
          direction="row-reverse"
        />

        <FeatureCard
          description="Monitor your sales, website traffic, and customer behavior in real-time with our dynamic dashboard. Gain valuable insights into daily trends, customer interactions, and sales performance. Stay informed and make quick, data-driven decisions to optimize your business. With up-to-the-minute analytics, you can respond to changes and opportunities as they happen."
          label="Analytics"
          dualHeading={{ text1: "Real-Time", text2: "Analytics" }}
          direction="row"
          featuredContent={
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${IMG_DECORATIVE_PATTERN})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
              justifyContent={"center"}
            >
              <Stack
                spacing={"16px"}
                sx={{
                  background: "#FFF",
                  borderRadius: "16px",
                  px: "21px",
                  py: "28px",
                }}
                alignItems={"center"}
              >
                <Stack spacing={"16px"} alignItems={"center"}>
                  <Typography
                    fontSize={21}
                    fontWeight={500}
                    lineHeight={"21px"}
                    fontFamily={"Archivo"}
                    color="#326212"
                  >
                    Total Sales Volume
                  </Typography>
                  <Typography
                    fontSize={30}
                    fontWeight={600}
                    lineHeight={"38px"}
                    fontFamily={"Manrope"}
                    color="#326212"
                  >
                    $900,000
                  </Typography>
                </Stack>
                <Stack direction={"row"} alignItems={"center"} spacing={"4px"}>
                  <IcUp />
                  <Typography
                    color="#66C61C"
                    fontSize={14}
                    fontWeight={500}
                    lineHeight={"20px"}
                  >
                    24.3%
                  </Typography>
                  <Typography
                    color="#326212"
                    fontSize={12}
                    fontWeight={400}
                    sx={{ px: "4px" }}
                    lineHeight={"18px"}
                  >
                    from last year
                  </Typography>
                </Stack>
                <InteractiveChartFeature />
              </Stack>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
}

export default Features;
