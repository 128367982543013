import React from "react";

export default function IC_STAR(props) {
  const width = props.width || "16px";
  const height = props.height || "16px";

  return (
    <svg
      height={height}
      width={width}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17972_57133)">
        <path
          d="M9.53834 1.6295C9.70914 1.21885 10.2909 1.21885 10.4617 1.6295L12.5278 6.59697C12.5998 6.77009 12.7626 6.88838 12.9495 6.90336L18.3123 7.33329C18.7556 7.36884 18.9354 7.92209 18.5976 8.21142L14.5117 11.7114C14.3693 11.8334 14.3071 12.0248 14.3506 12.2072L15.5989 17.4404C15.7021 17.873 15.2315 18.2149 14.8519 17.9831L10.2606 15.1787C10.1006 15.081 9.89938 15.081 9.73937 15.1787L5.14806 17.9831C4.76851 18.2149 4.29788 17.873 4.40108 17.4404L5.64939 12.2072C5.69289 12.0248 5.6307 11.8334 5.48831 11.7114L1.40241 8.21142C1.06464 7.92209 1.24441 7.36884 1.68773 7.33329L7.05054 6.90336C7.23744 6.88838 7.40024 6.77009 7.47225 6.59697L9.53834 1.6295Z"
          fill="#F2F4F7"
        />
        <g clip-path="url(#clip1_17972_57133)">
          <path
            d="M9.53834 1.6295C9.70914 1.21885 10.2909 1.21885 10.4617 1.6295L12.5278 6.59697C12.5998 6.77009 12.7626 6.88838 12.9495 6.90336L18.3123 7.33329C18.7556 7.36884 18.9354 7.92209 18.5976 8.21142L14.5117 11.7114C14.3693 11.8334 14.3071 12.0248 14.3506 12.2072L15.5989 17.4404C15.7021 17.873 15.2315 18.2149 14.8519 17.9831L10.2606 15.1787C10.1006 15.081 9.89938 15.081 9.73937 15.1787L5.14806 17.9831C4.76851 18.2149 4.29788 17.873 4.40108 17.4404L5.64939 12.2072C5.69289 12.0248 5.6307 11.8334 5.48831 11.7114L1.40241 8.21142C1.06464 7.92209 1.24441 7.36884 1.68773 7.33329L7.05054 6.90336C7.23744 6.88838 7.40024 6.77009 7.47225 6.59697L9.53834 1.6295Z"
            fill="#FDB022"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_17972_57133">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.0195312)"
          />
        </clipPath>
        <clipPath id="clip1_17972_57133">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.0195312)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
