import React from "react";

function HeroImgBlur() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1440"
      height="365"
      fill="none"
      viewBox="0 0 1440 365"
    >
      <path
        fill="url(#paint0_linear_21989_5219)"
        d="M-1 0h1442v365H-1V0z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_21989_5219"
          x1="720"
          x2="701.481"
          y1="295.5"
          y2="-20.206"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9FAFB"></stop>
          <stop offset="0.125" stopColor="#F9FAFB" stopOpacity="0.875"></stop>
          <stop offset="0.25" stopColor="#F9FAFB" stopOpacity="0.75"></stop>
          <stop offset="0.375" stopColor="#F9FAFB" stopOpacity="0.625"></stop>
          <stop offset="0.5" stopColor="#F9FAFB" stopOpacity="0.5"></stop>
          <stop offset="0.75" stopColor="#F9FAFB" stopOpacity="0.25"></stop>
          <stop offset="0.875" stopColor="#F9FAFB" stopOpacity="0.125"></stop>
          <stop offset="1" stopColor="#F9FAFB" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default HeroImgBlur;
