import { Button, SxProps } from "@mui/material";
import React from "react";

function ZButton(props: { label?: string; sx?: SxProps }) {
  return (
    <Button
      sx={{
        fontWeight: "600",
        textTransform: "none",
        padding: "10px 16px",
        color: "#fff",
        fontSize: "16px",
        borderRadius: "16px",
        border: "2px solid #FFF",
        background: " var(--Green-light-500, #66C61C)",
        /* Shadows/shadow-xs */
        boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        ":hover": {
          color: "black",
          background: "white",
        },
        ...props.sx,
      }}
    >
      {props.label}
    </Button>
  );
}

export default ZButton;
