import { Stack, Typography, useTheme } from "@mui/material";
import { useScroll, useTransform, motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";

export default function AnimatedTextLine(props: {
  text: any;
  from: number;
  to: number;
}) {
  const lineRef = useRef<any>(null);
  const theme = useTheme();
  const scroll = useScroll({});
  const [refresh, setRefresh] = useState(0);

  const lineWidth = useTransform(
    scroll.scrollY,
    [
      lineRef.current?.offsetTop - window.screen.availHeight + props.from,
      lineRef.current?.offsetTop - window.screen.availHeight + props.to,
    ],
    ["0%", "100%"]
  );

  const scale = useTransform(
    scroll.scrollY,
    [
      lineRef.current?.offsetTop - window.screen.availHeight + props.from,
      lineRef.current?.offsetTop - window.screen.availHeight + props.to,
    ],
    ["0.95", "1"]
  );

  useEffect(() => {
    setRefresh(new Date().getTime());
  });

  return (
    <Stack sx={{ position: "relative" }} ref={lineRef} key={refresh}>
      <motion.span
        style={{
          scale: scale,
          transformOrigin: "0 0",
        }}
      >
        <Typography
          sx={{
            fontSize: "28px",
            fontWeight: "600",
            letterSpacing: "-0.4px",
            color: "#98A2B3",
            width: "100%",
            position: "relative",
            [theme.breakpoints.down("desktop")]: {
              fontSize: "30px",
              width: "583px",
            },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "20px",
              width: "100%",
            },
          }}
        >
          <motion.span
            style={{
              color: "#101828",
              display: "inline-block",
              width: lineWidth,
              textWrap: "nowrap",
              position: "absolute",
              overflow: "hidden",
            }}
          >
            {props.text}
          </motion.span>
          <motion.span style={{ width: "100%", textWrap: "nowrap" }}>
            {props.text}
          </motion.span>
        </Typography>
      </motion.span>
    </Stack>
  );
}
