import React from "react";

function FloatingIcCard() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 45 45">
      <path
        fill="#F7F7F7"
        fillRule="evenodd"
        d="M25.474 6.617c3.37 0 6.015 0 8.101.236 2.13.24 3.856.742 5.29 1.875.43.34.829.714 1.19 1.12 1.221 1.37 1.766 3.028 2.026 5.064.03.234.045.351.018.454a.562.562 0 01-.327.371c-.099.04-.222.04-.469.04H3.975c-.247 0-.37 0-.469-.04a.561.561 0 01-.326-.37c-.027-.104-.012-.22.017-.455.26-2.036.805-3.695 2.025-5.064.363-.406.761-.78 1.19-1.12 1.436-1.133 3.162-1.635 5.291-1.875 2.086-.236 4.732-.236 8.101-.236h5.67zm16.859 16.127c0 3.158 0 5.66-.252 7.638-.26 2.036-.805 3.695-2.025 5.064-.362.406-.76.78-1.19 1.12-1.435 1.133-3.162 1.635-5.291 1.875-2.086.236-4.732.236-8.1.236h-5.67c-3.37 0-6.016 0-8.102-.236-2.129-.24-3.855-.742-5.29-1.875a9.396 9.396 0 01-1.19-1.12c-1.221-1.37-1.766-3.028-2.026-5.064-.252-1.978-.252-4.48-.252-7.638v-.194c0-1.054 0-2.036.01-2.95.005-.504.007-.757.168-.916.16-.159.415-.159.924-.159h37.184c.51 0 .764 0 .924.16.16.158.163.41.169.916.009.913.009 1.894.009 2.948v.195zm-22.9 7.231c0-.759.615-1.374 1.374-1.374h2.748a1.374 1.374 0 010 2.748h-2.748a1.374 1.374 0 01-1.374-1.374zm9.618-1.374a1.374 1.374 0 000 2.748h6.412a1.374 1.374 0 000-2.748h-6.412z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default FloatingIcCard;
