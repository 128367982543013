import { Stack, Typography } from "@mui/material";

function GetStartCard(props: {
  icon?: any;
  heading?: string;
  description?: string;
}) {
  return (
    <Stack
      sx={{
        width: "320px",
        padding: "24px",
        gap: "20px",
        border: " 1px solid var(--Gray-200, #EAECF0)",
        background: "var(--Base-White, #FFF)",
        borderRadius: "24px",
      }}
    >
      {/* <IC_SHOPPING_CART /> */}
      {props.icon}
      <Stack
        sx={{
          gap: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            color: "var(--Gray-900, #101828)",
            lineHeight: "30px",
          }}
        >
          {props.heading}
          {/* Easy Store Setup */}
        </Typography>
        <Typography
          sx={{
            color: "#475467",
          }}
        >
          {props.description}
          {/* Launch your online store in minutes with our user-friendly setup
          process. */}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default GetStartCard;
