import React from "react";

function IC_CREDIT_CARD() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.55 3.25c1.839 0 3.283 0 4.421.129 1.163.13 2.105.405 2.888 1.023.235.185.452.39.65.612.666.747.964 1.653 1.105 2.764.017.128.025.192.01.248a.307.307 0 01-.178.202c-.054.022-.121.022-.256.022H1.814c-.135 0-.202 0-.256-.022a.307.307 0 01-.178-.202c-.015-.056-.007-.12.01-.248.141-1.111.439-2.017 1.105-2.764.198-.222.415-.427.65-.612.783-.618 1.725-.892 2.888-1.023 1.138-.129 2.582-.129 4.421-.129h3.096zm9.202 8.803c0 1.724 0 3.09-.138 4.17-.141 1.11-.439 2.016-1.105 2.763a5.131 5.131 0 01-.65.612c-.783.618-1.725.892-2.888 1.023-1.138.129-2.582.129-4.421.129h-3.096c-1.839 0-3.283 0-4.421-.129-1.163-.13-2.105-.405-2.888-1.023a5.13 5.13 0 01-.65-.612c-.666-.747-.964-1.653-1.105-2.764-.138-1.08-.138-2.445-.138-4.17v-.105c0-.575 0-1.111.005-1.61.003-.276.004-.413.092-.5.088-.087.227-.087.504-.087h20.298c.277 0 .416 0 .504.087.088.087.09.224.092.5.005.498.005 1.034.005 1.61v.106zM10.252 16a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm5.25-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default IC_CREDIT_CARD;
