import { Stack, Typography } from "@mui/material";
import DualColorHeading from "../../landing/components/DualColorHeading";
import FloatingIcLogo from "../../../Assets/images/floating-cards/logo";
import FloatingIcCard from "../../../Assets/images/floating-cards/card";
import FloatingIcFile from "../../../Assets/images/floating-cards/file";
import FloatingIcStore from "../../../Assets/images/floating-cards/store";
import AnimatedTextLine from "../../landing/components/AnimatedTextLine";
import TestimonialCard from "../../landing/components/TestimonialCard";

function CtaSection() {
  return (
    <Stack
      className="features-section"
      sx={{
        alignItems: "center",
        justifyContent: "center",
        gap: "56px",
      }}
    >
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "center",
          gap: "56px",
          width: "1300px",
        }}
      >
        <DualColorHeading
          text1="Why"
          text2="Cashifysell?"
          color1="#101828"
          color2="#66C61C"
          fontSize={60}
          fontWeight={600}
          lineHeight={"72px"}
          props1={{ textAlign: "center" }}
        />
        <Stack
          sx={{
            alignItems: "start",
            justifyContent: "center",
            background: "#FFF",
            border: "1px solid #EAECF0",
            padding: " 80px 250px",
            borderRadius: "24px",
          }}
        >
          <AnimatedTextLine
            text={
              <>
                Choosing
                <span
                  style={{
                    display: "inline-block",
                    height: "24px",
                    transform: "translateY(5px)",
                    padding: "4px",
                  }}
                >
                  <FloatingIcLogo />
                </span>{" "}
                Cashifysell means you’re partnering with a platform
              </>
            }
            from={150}
            to={250}
          />

          <AnimatedTextLine
            text={<>that puts your business growth first.</>}
            from={250}
            to={300}
          />

          <Typography
            sx={{
              fontSize: "28px",
              color: "#101828",
              fontWeight: "600",
              position: "relative",
            }}
          >
            <br />
          </Typography>

          <AnimatedTextLine
            text={
              "With simple tools for setting up your store, secure payment options, "
            }
            from={300}
            to={400}
          />

          <AnimatedTextLine
            text={"and real-time insights."}
            from={400}
            to={450}
          />

          <Typography
            sx={{
              fontSize: "28px",
              color: "#101828",
              fontWeight: "600",
              position: "relative",
            }}
          >
            <br />
          </Typography>

          <AnimatedTextLine
            text={
              <>
                We provide everything you need to succeed in selling digital
                products.
              </>
            }
            from={450}
            to={500}
          />

          <AnimatedTextLine
            text={
              <>
                Our
                <span
                  style={{
                    display: "inline-block",
                    height: "40px",
                    width: "40px",
                    transform: "translateY(5px) rotate(-7.35deg) scale(0.8)",
                    padding: "4px",
                    border: "0.624px solid #FFF",
                    borderRadius: "8px",
                    background: "#66C61C",
                  }}
                >
                  <FloatingIcCard />
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    height: "40px",
                    width: "40px",
                    transform: "translateY(5px) rotate(8.35deg) scale(0.8)",
                    padding: "4px",
                    borderRadius: "8px",
                    border: "0.624px solid #FFF",
                    background: "#7A5AF8",
                  }}
                >
                  <FloatingIcFile />
                </span>{" "}
                <span
                  style={{
                    display: "inline-block",
                    height: "40px",
                    width: "40px",
                    transform: "translateY(5px) rotate(8.35deg) scale(0.8)",
                    padding: "4px",
                    borderRadius: "8px",
                    border: "0.624px solid #FFF",
                    background: "#2E90FA",
                  }}
                >
                  <FloatingIcStore />
                </span>{" "}
                transparent commission structure ensures you keep
              </>
            }
            from={500}
            to={550}
          />

          <AnimatedTextLine
            text={
              " more of your hard-earned money, while we take care of the rest—so"
            }
            from={550}
            to={600}
          />
          <AnimatedTextLine
            text={"you can focus on scaling your store."}
            from={600}
            to={650}
          />
        </Stack>
        <TestimonialCard />
      </Stack>
    </Stack>
  );
}

export default CtaSection;
