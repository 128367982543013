import { Stack, Typography } from "@mui/material";
import React from "react";
import DualColorHeading from "../components/DualColorHeading";
import ZButton from "../../../components/ZButton";
import GetStartCard from "../components/GetStartCard";
import IC_SHOPPING_CART from "../../../Assets/icons/IC_SHOPPING_CART";
import IC_SHEILD from "../../../Assets/icons/IC_SHEILD";
import IC_CUSTOMER_SUPPORT from "../../../Assets/icons/IC_CUSTOMER_SUPPORT";

function GetStart() {
  return (
    <>
      <div id="get-started" />
      <Stack
        className="getstarted-section"
        sx={{
          alignItems: "center",
          padding: "100px 0px",
          gap: "72px",
        }}
      >
        {/* Heading */}
        <Stack
          sx={{
            gap: "48px",
          }}
        >
          <Stack
            sx={{
              gap: "32px",
            }}
          >
            <Stack>
              <DualColorHeading
                text1="Start Your Journey with"
                color1="#101828"
                color2="#66C61C"
                fontSize={60}
                fontWeight={600}
                lineHeight={"72px"}
                props1={{ textAlign: "center" }}
              />
              <DualColorHeading
                text2="Cashifysell Today!"
                color1="#101828"
                color2="#66C61C"
                fontSize={60}
                fontWeight={600}
                lineHeight={"72px"}
                props1={{ textAlign: "center" }}
              />
            </Stack>
            <Typography
              width={"800px"}
              sx={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: "400",
                color: "#101828",
              }}
            >
              Unlock the potential of your digital products and reach a wider
              audience by creating your own store on Cashify. Whether you’re
              selling website templates, software, or bots, we provide the tools
              and support you need to succeed.
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              gap: "28px",
            }}
          >
            <ZButton
              label="Get Started"
              sx={{
                background: "#fff",
                color: "#101828",
                border: "2px solid var(--Gray-200, #EAECF0)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                ":hover": {
                  color: "#fff",
                  background: " var(--Green-light-500, #66C61C)",
                  border: "2px solid var(--Gray-200, #EAECF0)",
                },
              }}
            />
            <ZButton label="Create Your Store Now" />
          </Stack>
        </Stack>
        {/* Cards */}
        <Stack
          sx={{
            gap: "32px",
            flexDirection: "row",
          }}
        >
          <GetStartCard
            icon={<IC_SHOPPING_CART />}
            heading="Easy Store Setup"
            description="Launch your online store in minutes with our user-friendly setup
          process."
          />
          <GetStartCard
            icon={<IC_SHEILD />}
            heading="Secure Transactions"
            description="Your payments are safe with our industry-leading security measures."
          />
          <GetStartCard
            icon={<IC_CUSTOMER_SUPPORT />}
            heading="24/7 Customer Support"
            description="Get help anytime with our dedicated support team, available round the clock."
          />
        </Stack>
      </Stack>
    </>
  );
}

export default GetStart;
