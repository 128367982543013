import {
  Avatar,
  AvatarGroup,
  Box,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { motion } from "framer-motion";
import {
  IMG_CUSTOMER_1,
  IMG_CUSTOMER_2,
  IMG_CUSTOMER_3,
  IMG_CUSTOMER_4,
  IMG_CUSTOMER_5,
} from "../../../Assets/images/avatars";
import { IMG_HERO, IMG_HERO_BG } from "../../../Assets/images";
import ZButton from "../../../components/ZButton";
import HeroImgBlur from "../../../Assets/images/hero-img-blur";
import GlassBlocks from "../components/GlassBlocks";
import FloatingIcon from "../components/FloatingIcon";
import FloatingIcStore from "../../../Assets/images/floating-cards/store";
import FloatingIcFile from "../../../Assets/images/floating-cards/file";
import FloatingIcCard from "../../../Assets/images/floating-cards/card";

function Hero() {
  return (
    <Stack
      className="hero-section"
      alignItems={"center"}
      sx={{ width: "100%", overflow: "clip" }}
    >
      {/* Content Container : Width 1140px  */}
      <Stack
        sx={{
          maxWidth: "1140px",
          width: "100%",
          pt: "92px",
          position: "relative",
        }}
        spacing={"24px"}
        alignItems={"center"}
      >
        {/* Avatars */}
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"16px"}
        >
          {/* Icons  */}
          <AvatarGroup
            sx={{
              gap: "-11px",
              "& .MuiAvatar-root": {
                height: "44px",
                width: "44px",
                borderRadius: "12px",
                border: "2.063px solid #FFF",
              },
            }}
          >
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#FFF">Ahmed Jamal</Typography>
                  <Typography color="#DDD">CEO, UptoneDesign LLC</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_1} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#FFF">Shahid Ali</Typography>
                  <Typography color="#DDD">
                    Jr. Developer, Zexware Ltd
                  </Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_2} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#FFF">Ance Kalyar</Typography>
                  <Typography color="#DDD">RnD, Cashifybot Ltd</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_3} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#FFF">Aurangzaib</Typography>
                  <Typography color="#DDD">
                    Jr Developer, Zexware Ltd
                  </Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_4} />
            </Tooltip>
            <Tooltip
              placement="top"
              arrow
              title={
                <Stack>
                  <Typography color="#FFF">Abdul Sattar</Typography>
                  <Typography color="#DDD">CEO, Zexware Ltd</Typography>
                </Stack>
              }
            >
              <Avatar src={IMG_CUSTOMER_5} />
            </Tooltip>
          </AvatarGroup>
          {/* Supporting Text  */}
          <Stack>
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#000",
                lineHeight: "32px",
              }}
            >
              50,000+
            </Typography>
            <Typography fontSize={14} lineHeight={"20px"}>
              Digital Products Sold Worldwide
            </Typography>
          </Stack>
        </Stack>

        {/* Hero Text  */}

        <Stack spacing={"24px"} alignItems={"center"} sx={{ width: "100%" }}>
          <Typography
            textAlign={"center"}
            sx={{
              color: "#101828",
              fontSize: "72px",
              fontWeight: "600",
              display: "inline",
              lineHeight: "90px",
              letterSpacing: " -1.44px",
            }}
          >
            Launch and Grow
            <br /> Your{" "}
            <span style={{ color: "#66C61C" }}>Digital Products</span>
            <br /> Store Effortlessly
          </Typography>
          <Typography
            textAlign={"center"}
            sx={{ color: "#475467", fontSize: "18px", maxWidth: "700px" }}
          >
            Join thousands of creators selling digital products like templates,
            bots, software, and more. Cashify Store is the ultimate platform for
            creators to build, sell, and scale their stores with ease.
          </Typography>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2 }}
          >
            <ZButton label={"Start Your Store Today"} />
          </motion.div>

          <Stack
            sx={{
              position: "relative",
              width: "100%",
              height: "620px",
            }}
          >
            <motion.img
              src={IMG_HERO_BG}
              style={{
                position: "absolute",
                top: -50,
                left: 10,
                background: "transparent",
              }}
              initial={{
                transform: "translate(-100px, 100px)",
                opacity: 0,
              }}
              animate={{
                transform: "translate(0px, 0px)",
                zIndex: -3,
                opacity: 1,
              }}
              transition={{ duration: 1, delay: 1 }}
            />
            <motion.img
              src={IMG_HERO_BG}
              style={{
                position: "absolute",
                top: -120,
                left: 30,
              }}
              initial={{
                transform: "translate(-100px, 100px)",
                opacity: 0,
              }}
              animate={{
                transform: "translate(0px, 0px)",
                zIndex: -2,
                opacity: 1,
              }}
              transition={{ duration: 1, delay: 2 }}
            />
            <motion.img
              src={IMG_HERO}
              style={{
                position: "absolute",
                top: -190,
                left: 50,
              }}
              initial={{
                transform: "translate(-100px, 100px)",
                opacity: 0,
              }}
              animate={{
                transform: "translate(0px, 0px)",
                zIndex: -1,
                opacity: 1,
              }}
              transition={{ duration: 1, delay: 3 }}
            />
            <Box sx={{ position: "absolute", bottom: -10 }}>
              <HeroImgBlur />
            </Box>
          </Stack>
        </Stack>

        <GlassBlocks sx={{ position: "absolute", left: -250, top: 0 }} />
        <GlassBlocks sx={{ position: "absolute", right: -100, top: 160 }} />
        <GlassBlocks sx={{ position: "absolute", left: -170, top: 450 }} />

        <FloatingIcon
          initial={{
            top: 140,
            left: 100,
            transform: "rotateZ(-24deg)",
          }}
          animate={{
            left: -100,
            top: 80,
            transform: "rotateZ(-9.69deg)",
          }}
        >
          <FloatingIcStore />
        </FloatingIcon>

        <FloatingIcon
          style={{
            transform: "rotateZ(0)",
          }}
          initial={{
            top: 400,
            left: -50,
          }}
          animate={{
            left: -100,
            top: 400,
            transform: "rotateZ(-21.2deg)",
            background: "#7A5AF8",
          }}
        >
          <FloatingIcFile />
        </FloatingIcon>

        <FloatingIcon
          initial={{
            top: 200,
            right: 200,
            transform: "rotateZ(45deg)",
          }}
          animate={{
            right: 100,
            top: 100,
            transform: "rotateZ(0deg)",
            background: "#66C61C",
          }}
        >
          <FloatingIcCard />
        </FloatingIcon>
      </Stack>
    </Stack>
  );
}

export default Hero;
