import { Button, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import logoHeader from "../../../Assets/logos/LogoHeader.png";
import LinkButton from "../components/LinkButton";

let lastUpdate = 0;
function Header() {
  const [stick, setStick] = useState(window.scrollY > 150);
  function handleScroll() {
    if (new Date().getTime() - lastUpdate > 50) {
      setStick(Math.ceil(window.scrollY) > 150);
      lastUpdate = new Date().getTime();
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Stack
      sx={{
        transition: "all .7s",
        top: 0,
        left: 0,
        right: 0,
        alignItems: "center",
        justifyContent: "center",
        padding: "28px",
        zIndex: "100",
        position: "fixed",
      }}
    >
      <Stack
        sx={{
          background: "#101828",
          width: "1040px",
          padding: "24px 32px",
          borderRadius: "24px",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <img
            src={logoHeader}
            alt=""
            style={{
              height: "44px",
              objectFit: "contain",
            }}
          />
          <Stack spacing={"16px"} direction={"row"} alignItems={"center"}>
            <LinkButton
              label="Home"
              observable=".hero-section"
              onClick={() => {
                const _elem = window.document.querySelector(".hero-section");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/");
                }
              }}
            />
            <LinkButton
              label="Features"
              observable=".features-section"
              onClick={() => {
                const _elem =
                  window.document.querySelector(".features-section");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/#features");
                }
              }}
            />
            <LinkButton
              label="Pricing"
              observable=".pricing-section"
              onClick={() => {
                const _elem = window.document.querySelector(".pricing-section");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/#pricing");
                }
              }}
            />
            <LinkButton
              label="Sell Your Product"
              observable=".getstarted-section"
              onClick={() => {
                const _elem = window.document.querySelector(
                  ".getstarted-section"
                );
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/#get-started");
                }
              }}
            />
            <LinkButton
              label="Support"
              observable=".support-section"
              onClick={() => {
                const _elem = window.document.querySelector(".support-section");
                if (_elem) {
                  _elem.scrollIntoView({ behavior: "smooth" });
                } else {
                  window.open("/#faq");
                }
              }}
            />
          </Stack>

          <Button
            sx={{
              fontWeight: "600",
              textTransform: "none",
              padding: "10px 16px",
              color: "#fff",
              fontSize: "16px",
              borderRadius: "16px",
              background: " var(--Green-light-500, #66C61C)",
              /* Shadows/shadow-xs */
              boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              ":hover": {
                color: "black",
                background: "white",
              },
            }}
          >
            Log In
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Header;
